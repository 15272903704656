import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import {AuthContext} from '../../context/auth-context';
import './footer.css';
import logo from '../../resources/logo150px.png';



const FooterComponent = props => {
 
  const {token, logout, isAdmin} = useContext(AuthContext);

     return (
      <div className="footer-container">
        <div className="footer-centerItems">

        </div>
      </div>
      );
    }


export default FooterComponent;
