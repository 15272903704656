import React, { useState, useContext } from 'react';
import { Form, Button, Input, Spin, Icon, Checkbox, Alert } from 'antd';
import{UserOutlined, LockOutlined} from '@ant-design/icons'
import './Auth.css';
import {AuthContext} from '../context/auth-context';
import {BackendAddress} from './../Settings';

const AuthPage =(props) =>{
 
  const context = useContext(AuthContext);
  const [form] = Form.useForm();
  const [errorState, setErrorState] = useState();


  const submitHandler = event => {
    event.preventDefault();
    const email = form.getFieldValue("email");
    const password = form.getFieldValue("password");
    

    if (email.trim().length === 0 || password.trim().length === 0) {
      return;
    }

    let requestBody = {
      query: `
        query Login($email: String!, $password: String!) {
          login(email: $email, password: $password) {
            userId
            token
            tokenExpiration
            landesVerband
            isAdmin
            frontendPermissions{
              name
            }
          }
        }
      `,
      variables: {
        email: email,
        password: password
      }
    };

   

    fetch(BackendAddress, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        console.log(context);
        if(!resData.data && resData.errors)
        {
          setErrorState(resData.errors[0].message);
        }
        if (resData.data.login.token) {
          context.login(
            resData.data.login.token,
            resData.data.login.userId,
            resData.data.login.isAdmin,
            resData.data.login.landesVerband,
            resData.data.login.tokenExpiration,      
            resData.data.login.twoFactor     
          );
        }
        console.log(context);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const formItemLayout ={
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
 
    return (
      <span className="login-pagecontainer">
        <Form className="auth-form" layout="vertical" form={form}>
        {errorState && 
        <Form.Item><Alert message={errorState} type="error"></Alert></Form.Item>
        }
          <Form.Item name="email" rules={[{ required: true, message: 'Bitte geben Sie Ihre E-Mail ein!' }]}>
              <Input
                prefix={<UserOutlined type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="E-Mail"
                type="email"
              />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Bitte geben Sie Ihr Passwort ein!' }]}>
              <Input
                prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Passwort"
              />
          </Form.Item>
          <Form.Item valuePropName="remember" initialValue={true}>
           <Checkbox>Login merken</Checkbox>
            <a className="login-form-forgot" href="/passwordReset">
              Passwort vergessen
            </a>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" onClick={submitHandler.bind(null)} >
                Anmelden
              </Button>
              
            </Form.Item>
            
        
        </Form>
      </span>
    );
  
}

export default AuthPage;
