import React, { useState, useEffect} from 'react';
import {
    useParams
  } from "react-router-dom";
  
import './../components/global.less';
import './UserVerify.less';
import { Row, Col, Divider, Alert, Card, List, Avatar, Comment, Form, Button, Input, DatePicker } from 'antd';
import { CheckCircleTwoTone,CloseCircleTwoTone } from '@ant-design/icons';
import {useMutation, gql } from '@apollo/client';

const UserVerifyPage = (props) => {

    let{token} = useParams();

    const [alertText, setAlertText] = useState();
    const [successText, setSuccessText] = useState();
  
    const requestBody =gql `
        mutation VerifyUser($token:String! ){
          verifyUser(token:$token)
      }`;

    const [requestVerify, {loading, error, data}] = useMutation(requestBody);

    useEffect(() => {
        console.log(token);
        if(token)
        {
            const vars={};
            vars["token"]=token;
            requestVerify({variables:vars}).catch(err=>{console.log(err);}).then(res=>{
                if(res.data.verifyUser)
                {
                    setSuccessText("erfolgreich Verifiziert");
                }
                else{
                    setAlertText("Verifizierung fehlgeschlagen")
                }
            })
        }
    },[]);

    return (
      <React.Fragment>
          <div className="userverify-pageContainer">
              <div className="centerItems">
                {successText && (
                    <div>
                        <div>{successText}</div>
                        <CheckCircleTwoTone className="icon" twoToneColor="#52c41a"></CheckCircleTwoTone>
                        
                    </div>)}
                {alertText && (
                <div>
                    <div>{alertText}</div>
                    <CloseCircleTwoTone className="icon" twoToneColor="#eb2f96"></CloseCircleTwoTone>
                </div>)}
             </div>
          </div>
      </React.Fragment>
    );
};

export default UserVerifyPage;
