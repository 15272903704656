import { createContext } from "react";
import { BackendAddress } from "../Settings";

export const AuthContext =  createContext(null);

export const CheckTokenValid =async (token) =>{

    let requestBody = {
        query: `
          query CheckValidity {
            checkTokenValid{
              valid
              isAdmin
              userId
              landesVerband
              twoFactor
            }
          }
        `   
      };

      return await fetch(BackendAddress, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(resData => {
          return resData.data.checkTokenValid;
        })
        .catch(err => {
          console.log(err);
        });
};