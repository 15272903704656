import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Table, Checkbox, Row, Col, Tabs, Modal } from 'antd'

import { AuthContext } from '../../context/auth-context';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { SaveOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';




const RoleEdit = (props) => {


    const { token, userId, isAdmin } = useContext(AuthContext);

    const { showUserModalState, setShowUserModalState } = useState();
    const [ refresh, setRefresh ] = useState(false);
    const currentRoleRef = useRef();

    const authHeader = {
        context: {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    };

    const queryEntitiesBody = gql`
    query Entities{
        entities {
            _id
            name
        }
    },`;
    
    const updateBodyRole = gql`
    mutation updateRole($roleInput:RoleInput!){
        updateRole(roleInput:$roleInput) {
            _id
            name
            entityPermissions{
                entity{
                    _id
                    name
                }
                privileges{
                    read
                    write
                    delete
                }
            }
        }
    },`;

    const deleteBodyRole = gql`
    mutation DeleteRole($roleId:ID!){
        deleteRole(roleId:$roleId) 
    },`;

    const { loading: loadingEntities, error: errorEntities, data: dataEntities } = useQuery(queryEntitiesBody, authHeader);
    const [updateRoleMutation, { loading: loadingUpdateRole, error: errorUpdateRole, data: dataUpdateRole }] = useMutation(updateBodyRole, authHeader);
    const [deleteRoleMutation, { loading: loadingDeleteRole, error: errorDeleteRole, data: dataDeleteRole }] = useMutation(deleteBodyRole, authHeader);

    useEffect(() => {
        if (dataEntities && props.role) {
            currentRoleRef.current = props.role;

            currentRoleRef.current.entityPermissions = dataEntities.entities.map(entity => {
                let permission = props.role.entityPermissions.find(x => x.entity._id === entity._id);
                if(permission){
                    return {
                        entity: entity,
                        privileges: permission.privileges,
                    }
                }
                else{
                    return {
                        entity: entity,
                        privileges: {read:false, write:false, delete:false},
                    }
                }
               
            });
            
            console.log(currentRoleRef.current)
            setRefresh(!refresh);
        }


    }, [dataEntities]);

    const changePrivilegeHandler = async (key, value, record) => {
        console.log(value);
        switch (key) {
            case "read":
                currentRoleRef.current.entityPermissions[currentRoleRef.current.entityPermissions.findIndex(x => x.entity.name === record.entity.name)].privileges.read = value;
                break;
            case "write":
                currentRoleRef.current.entityPermissions[currentRoleRef.current.entityPermissions.findIndex(x => x.entity.name === record.entity.name)].privileges.write = value;
                break;
            case "delete":
                currentRoleRef.current.entityPermissions[currentRoleRef.current.entityPermissions.findIndex(x => x.entity.name === record.entity.name)].privileges.delete = value;
                break;

            default:
                break;
        }
        console.log(currentRoleRef.current.entityPermissions);
    }

    const updateRoleHandler = async () => {
        console.log(currentRoleRef.current);
        
        updateRoleMutation({
            variables: {
                roleInput: {
                    id: currentRoleRef.current._id,
                    name: currentRoleRef.current.name,
                    entityPermissions: currentRoleRef.current.entityPermissions.map(permission => {
                        return {
                            entity: {
                                name: permission.entity.name,
                                id: permission.entity._id,
                            },
                            privileges:
                            {
                                read: permission.privileges.read,
                                write: permission.privileges.write,
                                delete: permission.privileges.delete,
                            }
                        }
                    })
                }
            }
        })

    }

    const deleteRoleHandler = async (role) => {
        var res = await deleteRoleMutation({
            variables: { roleId: role._id }
        })
        props.refreshRoles();
    }

    const entityColumns = [
        {
            title: 'Entity',
            dataIndex: ['entity', 'name'],
            key: ['entity', '_id'],
        },
        {
            title: (<span><Row>Berechtigungen</Row><Row>R | W | D</Row></span>),
            render: (record) => (
                <Row>
                    {console.log(record)}
                    <Checkbox defaultChecked={record.privileges.read} onChange={(e) => { changePrivilegeHandler("read", e.target.checked, record) }}></Checkbox>
                    <Checkbox defaultChecked={record.privileges.write} onChange={(e) => { changePrivilegeHandler("write", e.target.checked, record) }}></Checkbox>
                    <Checkbox defaultChecked={record.privileges.delete} onChange={(e) => { changePrivilegeHandler("delete", e.target.checked, record) }}></Checkbox>
                </Row>
            )
        },
    ];


    return (
        <React.Fragment>
            {currentRoleRef.current &&
                <div key={currentRoleRef._id}>
                    <Row>
                        <h2>{currentRoleRef.current.name}</h2>
                        <Button onClick={ updateRoleHandler}><SaveOutlined></SaveOutlined></Button>
                        <Button onClick={() => { deleteRoleHandler(currentRoleRef) }}><DeleteOutlined></DeleteOutlined></Button>
                    </Row>
                    <Row>
                        {dataEntities && currentRoleRef.current.entityPermissions &&
                        <Table pagination={false} dataSource={currentRoleRef.current.entityPermissions} columns={entityColumns} >
                        </Table>
                        }
                    </Row>
                </div>
            }
        </React.Fragment>);

}

export default RoleEdit;
