import React, { useState, useEffect, useContext} from 'react';
import './../components/global.css';
import Spinner from '../components/Spinner/Spinner';
import {AuthContext} from '../context/auth-context';
import './UserProfile.css';
import { Row, Col, Divider, Image, Card, List, Avatar, Comment, Form, Button, Input, DatePicker } from 'antd';
import {useQuery,  useLazyQuery, gql, useMutation } from '@apollo/client';
import{AntDesignOutlined, EditOutlined, CloseOutlined, CheckOutlined, SaveOutlined} from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE'
import Checkbox from 'antd/lib/checkbox/Checkbox';

const UserProfilePage = (props) => {

    const {token, userId} = useContext(AuthContext);
    const [editMode, setEditMode] = useState(false);

    const [name, setName] = useState("");
    const [nameChanged, setNameChanged] = useState("");

    const [firstName, setFirstName] = useState("");
    const [firstNameChanged, setFirstNameChanged] = useState("");

    const [birthDate, setBirthdate] = useState();
    const [birthDateChanged, setBirthdateChanged] = useState();

    const [street, setStreet] = useState("");
    const [streetChanged, setStreetChanged] = useState("");

    const [number, setNumber] = useState("");
    const [numberChanged, setNumberChanged] = useState("");

    const [zipCode, setZipCode] = useState("");
    const [zipCodeChanged, setZipCodeChanged] = useState("");

    const [city, setCity] = useState("");
    const [cityChanged, setCityChanged] = useState("");

    const [mail, setMail] = useState("");
    const [mailChanged, setMailChanged] = useState("");

    const [dataChanged, setDataChanged] = useState(false);
  
    const [mgv, setMgv] = useState("");

    const[syncMgv, setSyncMgv] = useState("");
    const[syncMgvChanged, setSyncMgvChanged] = useState("");
  
  
  
    const requestBody =gql `
        query User{
                user
                {
                    _id
                    name
                    firstName
                    birthDate
                    email
                    mgvId
                    syncMgv
                    _address
                    {
                        _id
                        street
                        number
                        zipCode
                        city
                    }
                    _doctor
                    {
                        _id
                        title
                    }
                    _student
                    {
                        _id
                        semester
                    }
                }
            }
        `;

        const requestBodyUpdate =gql `
        mutation UpdateUser(
            $userId:ID!
            $name:String! 
            $firstName:String! 
            $birthDate:String
            $email:String!
            $street:String! 
            $number:String!
            $zipCode:String!
            $city:String!
            $syncMgv:Boolean){
                updateUser(userInput:
                {
                    userId:$userId
                    name:$name 
                    firstName:$firstName
                    birthDate:$birthDate
                    email:$email
                    syncMgv:$syncMgv
                    _address:{
                        street:$street
                        number:$number
                        zipCode:$zipCode
                        city:$city
                    }
                })
                {
                    _id
                    name
                    firstName
                    birthDate
                    email
                    mgvId
                    syncMgv
                    _address
                    {
                        _id
                        street
                        number
                        zipCode
                        city
                    }
                    _doctor
                    {
                        _id
                        title
                    }
                    _student
                    {
                        _id
                        semester
                    }
                }
            }
        `;
    const authHeader = {context: {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }};

    const [getUserQuery,{loading, error, data}] = useLazyQuery(requestBody, authHeader);

    const [updateUserQuery,{loading:loadingUpdate,error: errorUpdate, data:dataUpdate}] = useMutation(requestBodyUpdate, authHeader)

    useEffect(() => {
        if(data && data.user)
        {
           setData(data.user);
        }
        else
        {
            getUserQuery();
        }
    },[data]);

    const setData = (user) =>
    {
        setName(user.name);
        setFirstName(user.firstName);
        if(user.birthDate)
        {
            setBirthdate(moment(user.birthDate));
        }
        else
        {
            setBirthdate("");
        }
        setMail(user.email);
        if(user._address)
        {
            setStreet(user._address.street);
            setNumber(user._address.number);
            setZipCode(user._address.zipCode);
            setCity(user._address.city);
        }
        setSyncMgv(user.syncMgv);
        if(user.mgvId)
        {
            setMgv(true);
        }
        setDataChanged(false);
    };

    const toggleEditHandler = ()=>{
        setEditMode(!editMode);

        setNameChanged(name);
        setFirstNameChanged(firstName);
        if(birthDate==="")
        {
            setBirthdateChanged(moment());
        }
        else
        {  
            setBirthdateChanged(birthDate)
        }
        setStreetChanged(street);
        setNumberChanged(number);
        setZipCodeChanged(zipCode);
        setCityChanged(city);
        setMailChanged(mail);
        setSyncMgvChanged(syncMgv);
    };

    const saveEditInput = () =>{
        
        //validate todo
        setDataChanged(true);
        setName(nameChanged);
        setFirstName(firstNameChanged);
        if(birthDateChanged.format("DD.MM.YYYY") !== moment().format("DD.MM.YYYY"))
        {
            setBirthdate(birthDateChanged);
        }
        setStreet(streetChanged);
        setNumber(numberChanged);
        setZipCode(zipCodeChanged);
        setCity(cityChanged);
        setMail(mailChanged);
        setSyncMgv(syncMgvChanged);
        setEditMode(false);
        
    }

    const updateUserOnServer = async ()=>{
        const vars = {};
        vars["userId"] = userId;
        vars["name"] = name;
        vars["firstName"] = firstName;
        vars["email"] = mail;
        vars["street"] = street;
        vars["number"] = number;
        vars["zipCode"] = zipCode;
        vars["city"] = city;
        vars["syncMgv"] = syncMgv;
        if(birthDate)
        {
            vars["birthDate"] = birthDate;
        }


        updateUserQuery({variables:vars}).catch(err=>{console.log(err);}).then(res=>{
            if(res.user)
            {
                setData(res.user);
            }
        })
        
    };

    const inputChangeHandler= (key, e)=>{
        switch (key){
            case "name":
                console.log(e);
                setNameChanged(e.target.value);
                break;
            case "firstName":
                setFirstNameChanged(e.target.value);
                break;
            case "birthDate":
                console.log(e);
                setBirthdateChanged(e);
                break;
            case "street":
                setStreetChanged(e.target.value);
                break;
            case "number":
                setNumberChanged(e.target.value);
                break;
            case "zipCode":
                setZipCodeChanged(e.target.value);
                break;
            case "city":
                setCityChanged(e.target.value);
                break;
            case "mail":
                setMailChanged(e.target.value);
                break;
            default:
                case "syncMgv":
                    setSyncMgvChanged(e.target.checked);
                break;
        }
    };

    return (
      <React.Fragment>
          <div className="HeaderSpace"></div>
          <div className="Container">
            <Row justify="center">
                <Avatar justify="center"
                        size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                        }}
                        icon={<AntDesignOutlined />}
                    />
                </Row>
            <Row className="HeaderRow" justify="center" gutter={[24,24]} align="bottom">
                <Col>
                    <Row className="HeaderSubRow" justify="center" align="middle">
                        <div className="HeaderText">Profilübersicht
                        </div>
                        {editMode ===true ?(
                            <div>
                                <CloseOutlined className="ClickableIcon EditButton" onClick={toggleEditHandler.bind(null)}></CloseOutlined>
                                <CheckOutlined className="ClickableIcon EditButton" onClick={saveEditInput.bind(null)}></CheckOutlined> 
                            </div>):
                            <div>
                            <EditOutlined className="ClickableIcon EditButton" onClick={toggleEditHandler.bind(null)}/>
                            {dataChanged && 
                                <SaveOutlined className ="ClickableIcon EditButton" onClick={updateUserOnServer.bind(null)}></SaveOutlined>
                            }
                            </div>
                        }
                    </Row>
                </Col>
            </Row>
            <Row className="DataGrid" justify="center" gutter={[24,24]}>
                <Col span={6}></Col>
                <Col className="DataColumn" span={6} >
                    <Row className="DataRow">
                        <p  className="TableElement" justify="start">Name:</p>
                        {editMode ===true ?
                        <Input className="TableElement" type="text" id="name" value={nameChanged} onChange={inputChangeHandler.bind(null, "name")}></Input>:
                        <p  className="TableElement" justify="start" >{name}</p>                    
                        }
                    </Row>
                    <Row className="DataRow">
                        <p  className="TableElement" justify="start">Vorname:</p>
                        {editMode ===true ?
                        <Input className="TableElement" type="text" id="firstName" value={firstNameChanged} onChange={inputChangeHandler.bind(null, "firstName")}></Input>:
                        <p  className="TableElement" justify="start" >{firstName}</p>                    
                        }
                    </Row>
                    <Row className="DataRow">
                        <p className="TableElement" justify="start">Geburtsdatum:</p>
                        {editMode ===true ?
                        <DatePicker className="TableElement" locale={locale} dropdownClassName="ant-picker-dropdown-placement-bottomCenter" id="birthDate" value={birthDateChanged} format="DD.MM.YYYY" onChange={inputChangeHandler.bind(null, "birthDate")}>
                        </DatePicker>:
                        <div className="TableElement">{birthDate && 
                            <p  justify="start" >{birthDate.format('DD.MM.YYYY')}</p>                    
                            }
                        </div>
                        }
                    </Row>
                    <Row className="DataRow">
                        <p className="TableElement" justify="start">Straße + Nr.:</p>
                        {editMode ===true ?
                        <div className="TableElement">
                        <Input  type="text" style={{width: '80%', height:'100%'}} id="street" value={streetChanged} onChange={inputChangeHandler.bind(null, "street")}></Input>
                        <Input  type="text" style={{width: '20%', height:'100%'}} id="street" value={numberChanged} onChange={inputChangeHandler.bind(null, "number")}></Input>
                        </div>:
                        <p className="TableElement" justify="start" >{street +' '+number}</p>
                        }
                    </Row>
                    <Row className="DataRow">
                        <p className="TableElement" justify="start">Postleitzahl:</p>
                        {editMode ===true ?
                        <div className="TableElement">
                        <Input  type="text" style={{width: '30%', height:'100%'}} id="street" value={zipCodeChanged} onChange={inputChangeHandler.bind(null, "zipCode")}></Input>
                        <Input  type="text" style={{width: '70%', height:'100%'}} id="street" value={cityChanged} onChange={inputChangeHandler.bind(null, "city")}></Input>
                        </div>:
                        <p className="TableElement" justify="start" >{zipCode + ' ' +city}</p>
                        }
                    </Row>
                </Col>
                <Col className="DataColumn" span={6}>
                    <Row className="DataRow">
                        <p className="TableElement" justify="start">E-Mail:</p>
                        {editMode ===true ?
                        <Input className="TableElement" type="text" id="mail" value={mailChanged} onChange={inputChangeHandler.bind(null, "mail")}></Input>:
                        <p  className="TableElement" justify="start" >{mail}</p>                    
                        }
                    </Row>
                    <Row className="DataRow">
                        <p className="TableElement" justify="start">Mitglied MB:</p>
                        <p  className="TableElement" justify="start" >{mgv}</p>
                    </Row>
                    <Row className="DataRow">
                    {editMode ===true ?
                        <Checkbox disabled={!editMode} checked={syncMgvChanged} onChange={inputChangeHandler.bind(null, "syncMgv")}>Daten mit MB-Verwaltung synchronisieren:</Checkbox>:
                        <Checkbox disabled={!editMode} checked={syncMgv} onChange={inputChangeHandler.bind(null, "syncMgv")}>Daten mit MB-Verwaltung synchronisieren:</Checkbox>
                    }
                    </Row>
                </Col>
                <Col span={6}></Col>
            </Row>
        </div>
      </React.Fragment>
    );
};

export default UserProfilePage;
