import React, { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';
import { Row, Col, Button, Table, Modal, Divider, Select, DatePicker, Input, Popconfirm } from 'antd';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import Backdrop from './../components/Backdrop/Backdrop'
import './Berechtigungen.css';
import './../components/global.less';
import 'antd/dist/antd.less';
import moment from 'moment';
import 'react-awesome-query-builder/lib/css/styles.css';
//import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const BerechtigungenPage = props => {

  const { token, userId } = useContext(AuthContext);
  const [backdropVisibleState, setBackdropVisibleState] = useState(false);
  const editBerechtigungRef = useRef({});

  const queryBerechtigungen = gql`
  query Berechtigungen{
          berechtigungen
          {
              _id
              _landesVerbandCreator
              {
                _id
                name
              }
              _landesVerbandReceiver
              {
                _id
                name
              }
              _landKreise
              {
                  _id
                  name
                  _localities
                  {
                    _id
                    postal_code
                    locality
                  }
              }
              _creator
              {
                _id
                name
                firstName
                email
              }
              startDate
              endDate
              info
          }
      }
  `;

  const queryLandesVerbaende = gql`
query LandesVerbaende{
        landesVerbaende
        {
            _id
           name            
        }
    }
`;

  const queryLandesVerband = gql`
query LandesVerband{
        landesVerband
        {
            _id
           name 
           _landKreise{
             _id
             name
           }           
        }
    }
`;

  const mutationBerechtigung = gql`
mutation UpdateBerechtigung($id:ID $landesVerbandReceiverId:ID! $_landKreiseIds:[ID]! $startDate:String! $endDate:String! $info:String!)
{
  updateBerechtigung(berechtigungInput:{id:$id landesVerbandReceiverId:$landesVerbandReceiverId _landKreiseIds:$_landKreiseIds startDate:$startDate endDate:$endDate info:$info})
  {
      _landesVerbandCreator
      {
        _id
        name
      }  
      _landesVerbandReceiver
      {
        _id
        name
      }
      _creator{
        _id
        name
        firstName
        email
      }
      startDate
      endDate
      info  
  }
}`;


const deleteBerechtigungBody = gql`
mutation DeleteBerechtigung($berechtigungId:ID!)
{
  deleteBerechtigung(berechtigungId:$berechtigungId) 
}`;

  const authHeader = {
    context: {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  };

  const { loading: loadingBerechtigungen, error: errorBerechtigungen, data: dataBerechtigungen, refetch } = useQuery(queryBerechtigungen, authHeader);
  const { loading: loadingLandesVerbaende, error: errorLandesVerbaende, data: dataLandesVerbaende } = useQuery(queryLandesVerbaende, authHeader);
  const { loading: loadingLandesVerband, error: errorLandesVerband, data: dataLandesVerband } = useQuery(queryLandesVerband, authHeader);
  const [updateBerechtigung, { loading: loadingUpdateBerechtigung, error: errorUpdateBerechtigung, data: dataUpdateBerechtigung }] = useMutation(mutationBerechtigung, authHeader);
  const [deleteBerechtigung, { loading: loadingDeleteBerechtigung, error: errorDeleteBerechtigung, data: dataDeleteBerechtigung }] = useMutation(deleteBerechtigungBody, authHeader);

  useEffect(() => {
  }, [dataUpdateBerechtigung, dataBerechtigungen])


  const addBerechtigungHandler = async () => {
    editBerechtigungRef.current = { _landKreise: [] }
    console.log(editBerechtigungRef.current);
    setBackdropVisibleState(!backdropVisibleState);
  };
  const editBerechtigungHandler = async (berechtigung) => {
    editBerechtigungRef.current = {};
    editBerechtigungRef.current.id = berechtigung._id;
    editBerechtigungRef.current.landesVerbandReceiverId = berechtigung._landesVerbandReceiver._id;
    editBerechtigungRef.current._landKreise = berechtigung._landKreise.map(kreis => { return kreis._id });
    editBerechtigungRef.current.startDate = berechtigung.startDate;
    editBerechtigungRef.current.endDate = berechtigung.endDate;
    editBerechtigungRef.current.info = berechtigung.info;
    console.log(editBerechtigungRef.current);
    setBackdropVisibleState(true);
  };

  const deleteBerechtigungHandler = async (id) => {
    var res = await deleteBerechtigung({ variables: { berechtigungId: id}});
    await refetch();
  };

  const getDefaultDates = () => {
    var defaultValues = [];
    if (editBerechtigungRef.current.startDate) {
      defaultValues[0] = new moment(editBerechtigungRef.current.startDate);
    }
    if (editBerechtigungRef.current.endDate) {
      defaultValues[1] = new moment(editBerechtigungRef.current.endDate);
    }
    return defaultValues;
  }

  const saveBerechtigung = async () => {
    const vars = {};
    vars["id"] = editBerechtigungRef.current.id;
    vars["landesVerbandReceiverId"] = editBerechtigungRef.current.landesVerbandReceiverId;
    vars["_landKreiseIds"] = editBerechtigungRef.current._landKreise;
    vars["startDate"] = editBerechtigungRef.current.startDate;
    vars["endDate"] = editBerechtigungRef.current.endDate;
    vars["info"] = editBerechtigungRef.current.info;

    console.log(vars);
    var res = await updateBerechtigung({ variables: vars })
    await refetch();
    setBackdropVisibleState(false);
  }

  const handleChangeLandesVerband = (value, event) => {
    console.log(event);
    editBerechtigungRef.current.landesVerbandReceiverId = event.key;
  };

  const handleSelectLandKreise = (value, event) => {
    editBerechtigungRef.current._landKreise.push(event.key)
    console.log(editBerechtigungRef.current._landKreise);
  };

  const handleDeSelectLandKreise = (value, event) => {
    editBerechtigungRef.current._landKreise = editBerechtigungRef.current._landKreise.filter(landKreis => {
      if (landKreis === event.key) {
        return false;
      }
      return true;
    });
    console.log(editBerechtigungRef.current._landKreise)
  };

  const handleChangeDates = (values) => {
    editBerechtigungRef.current.startDate = new moment(values[0]).toISOString();
    editBerechtigungRef.current.endDate = new moment(values[1]).toISOString();
  }

  const handleChangeInfo = (event) => {
    console.log(event);
    editBerechtigungRef.current.info = event.target.value;
    console.log(editBerechtigungRef.current);
  }

  //Define Columns and data keys for rationg overview table
  const ratingOverviewColumns = [
    {
      title: 'Landesverband',
      dataIndex: ['_landesVerbandReceiver', 'name'],
      key: '_landesVerbandReceiver',
    },
    {
      title: 'Ersteller',
      dataIndex: ['_creator'],
      key: '_creator',
      render: (record) => <label>{record.firstName + " " + record.name}</label>
    },
    {
      title: 'Start',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (record) => <label>{moment(record).format('DD.MM.YYYY')}</label>
    },
    {
      title: 'Ende',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (record) => <label>{moment(record).format('DD.MM.YYYY')}</label>
    },
    {
      title: 'Aktion',
      key: 'action',
      render: (record) => (
        <div>
          <Button onClick={editBerechtigungHandler.bind(null, record)}>
            <EditOutlined></EditOutlined>
          </Button>
          <Popconfirm
            title="Berechtigung löschen?"
            description="Sind Sie sicher, dass Sie diese Berechtigung löschen wollen?"
            onConfirm={deleteBerechtigungHandler.bind(null, record._id)}
            okText="Ja"
            cancelText="Nein"
          >
            <Button>
              <DeleteOutlined></DeleteOutlined>
            </Button>
          </Popconfirm>

        </div>
      ),
    },
  ];

  const selectOptions = (list) => {
    return list.map(item => {
      return (<Option value={item.key} key={item._id}>{item.name}</Option>)
    })
  }


  return (
    <React.Fragment>
      <div className="ratingOverview pageContainer-berechtigungen">

        <Row gutter={[24, 24]} style={{ padding: "16px" }}>
          <Col span={24} className="activeRatingBlueprintContainer">

            <div className="stretchElement">
              <Button onClick={addBerechtigungHandler.bind(null)}>
                <PlusOutlined></PlusOutlined>
              </Button>
            </div>

            {dataBerechtigungen ?
              <Table className="stretchElement"
                pagination={false}
                columns={ratingOverviewColumns}
                dataSource={dataBerechtigungen.berechtigungen}
                loading={loadingBerechtigungen}
                expandable={{
                  expandedRowRender: record => <p style={{ margin: 0 }}>{record._landKreise.map(kreis => { return kreis.name + ", " })}</p>,
                }}></Table>
              : <div> could not load blueprint data</div>
            }
          </Col>
        </Row>
      </div>
      {backdropVisibleState &&
        <Modal visible={backdropVisibleState} onCancel={() => setBackdropVisibleState(false)} width={"600px"} onOk={saveBerechtigung.bind()}>
          <Col style={{ width: "100%", backgroundColor: "white" }}>
            <Divider orientation="left" title="Landesverband">
              Landesverband
            </Divider>
            <Select style={{ width: "100%" }}
              defaultValue={editBerechtigungRef.current.landesVerbandReceiverId ? editBerechtigungRef.current.landesVerbandReceiverId : undefined}


              onSelect={(value, event, key) => handleChangeLandesVerband(value, event, key)}
              showSearch
              placeholder="Landesverband wählen"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {dataLandesVerbaende &&
                selectOptions(dataLandesVerbaende.landesVerbaende)}
            </Select>
            <Divider orientation="left" title="Landkreise">
              Landkreise
            </Divider>
            <Select style={{ width: "100%" }}
              defaultValue={editBerechtigungRef.current._landKreise}
              mode="multiple"
              onDeselect={(key, event) => handleDeSelectLandKreise(key, event)}
              onSelect={(key, event) => handleSelectLandKreise(key, event)}
              showSearch
              placeholder="Landkreise wählen"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {dataLandesVerband &&
                selectOptions(dataLandesVerband.landesVerband._landKreise)}
            </Select>
            <Divider orientation="left" title="Start">
              Start &#38; Ende
            </Divider>
            <RangePicker style={{ width: "100%" }} format={'DD.MM.YYYY'} onChange={(values) => handleChangeDates(values)}
              defaultValue={getDefaultDates()} />
            <Divider orientation="left" title="Info">
              Info
            </Divider>
            <TextArea rows={4} onChange={(event) => handleChangeInfo(event)} defaultValue={editBerechtigungRef.current.info ? editBerechtigungRef.current.info : undefined} />
          </Col>

        </Modal>
      }
    </React.Fragment>
  );
}

export default BerechtigungenPage;
