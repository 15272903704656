import React, { useState, useContext, useRef } from 'react';
import ReactDOM from "react-dom";
import {useQuery,  useLazyQuery, gql, useMutation } from '@apollo/client';
import { Form, Button, Input, Table, Icon, Spin } from 'antd';
import{QuestionCircleTwoTone, CloseCircleTwoTone,CheckCircleTwoTone, ExclamationCircleTwoTone} from '@ant-design/icons'
import './Auth.css';
import {AuthContext} from '../context/auth-context';
import { useFilePicker } from 'use-file-picker';
import { Importer, ImporterField,deDE  } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { encode } from 'punycode';
import moment from "moment"
import { Options } from 'smooth-scrollbar/options';

const CsvUploadPage =(props) =>{
 
  const {token, userId} = useContext(AuthContext);
  const authHeader = {context: {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }};

  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: '.txt',
  });
  
  const updateAllMembersBody = gql `
  mutation UpdateAllMembers($membersInput:[MemberInput]!)
  {
    updateAllMembers(membersInput:$membersInput)
    {
      _id
      name
      firstName
      birthDate
      email
      mgvId
      briefAnrede
      _landesVerband{
        _id
        name
      } 
      _landKreis{
        _id
        name
      }
      _address{
        throughfare
        postal_code
        locality
      }
      nameAg
      _addressAg{
        throughfare
        postal_code
        locality
      }
      lastUpdated
      tarifBindung
      status
    }
  }`;
const [updateAllMembers,{loading:loadingUpdateAllMembers,error: errorUpdateAllMembers, data:dataUpdateAllMembers}] = useMutation(updateAllMembersBody, authHeader);
 
const memberList = [];
const memberListRef = useRef();
const [memberListState, setMemberListState] = useState();
const columns= [
{
  title:"Vorname",
  dataIndex:'firstName',
  key:"firstName"
},
{
  title:"Nachname",
  dataIndex:'name',
  key:"name"
},
{
  title:"Geburtsdatum",
  dataIndex:"birthDate",
  key:"birthDate",
  render:(record)=><p style={{marginTop:"auto", marginBottom:"auto"}}>{moment(record).format("DD.MM.YYYY")}</p>
},
{
  title:"Email",
  dataIndex:'email',
  key:"email"
},
{
      title:"Mitgliedsnummer",
      dataIndex:['mgvId'],
      key:"mgvId"
},    
{
  title:"Adresse",
  dataIndex:"_address",
  key:"_address",
  render:(address)=><p style={{marginTop:"auto", marginBottom:"auto"}}>{address.postal_code} {address.locality}</p>
},
{
  title:"Adresse Arbeitgeber",
  dataIndex:"_addressAg",
  key:"_addressAg",
  render:(address)=><p style={{marginTop:"auto", marginBottom:"auto"}}>{address.throughfare}<br></br>{address.postal_code} {address.locality}</p>
},
{
  title:"Arbeitgeber",
  dataIndex:"nameAg",
  key:"nameAg",
},
{  
  title:"Tarifbindung",
  dataIndex:"tarifBindung",
  key:"tarifBindung",
},
{
  title:"Landesverband",
  dataIndex:['_landesVerband','name'],
  key:"_landesVerband"
},    
{
  title:"Landkreis",
  dataIndex:["_landKreis","name"],
  key:"_landKreis",
},
{
  title:"Anrede",
  dataIndex:"briefAnrede",
  key:"briefAnrede"
},
{
  title:"Ausgetreten",
  dataIndex:"delete",
  key:"delete"
},
{
  title:"Status",
  dataIndex:"status",
  key:"status",
  render:(record)=><div style={{marginTop:"auto", marginBottom:"auto", display:"flex", flexDirection:"column"}}>
    {record === "none" &&  <div style={{display:'flex', flexDirection:"column", justifyContent:"center"}}><QuestionCircleTwoTone twoToneColor="grey" style={{fontSize:"20pt"}}></QuestionCircleTwoTone><p style={{textAlign:"center"}}>nicht gesendet</p></div>}
    {record === "updated" &&  <div style={{display:'flex', flexDirection:"column",justifyContent:"center"}}><CheckCircleTwoTone twoToneColor="green" style={{fontSize:"20pt"}}/><p style={{textAlign:"center"}}>Aktualisiert</p></div>}
    {record === "deleted" &&  <div style={{display:'flex', flexDirection:"column",justifyContent:"center"}}><CloseCircleTwoTone twoToneColor="green" style={{fontSize:"20pt"}}/><p style={{textAlign:"center"}}>Gelöscht</p></div>}
    {record === "error" &&  <div style={{display:'flex', flexDirection:"column",justifyContent:"center"}}><ExclamationCircleTwoTone twoToneColor="red" style={{fontSize:"20pt"}}/><p style={{textAlign:"center"}}>Datensatz fehlerhaft</p></div>}
  </div>
}];

    return (
<div style={{margin:"32px"}}>
<Importer
 locale={deDE}
  assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
  restartable={false} // optional, lets user choose to upload another file when import is complete
  onStart={({ file, preview, fields, columnFields }) => {
    // optional, invoked when user has mapped columns and started import
   // prepMyAppForIncomingData();
  }}
  processChunk={async (rows, { startIndex }) => {
    // required, may be called several times
    // receives a list of parsed objects based on defined fields and user column mapping;
    // (if this callback returns a promise, the widget will wait for it before parsing more data)
    if(!memberListRef.current)
    {
      memberListRef.current =[]
    }
    for (var row of rows) {
      memberList.push({
        name:row.nachname,
        firstName:row.vorname,
        birthDate: moment(row.geburtsdatum,"DD.MM.YYYY").local().toISOString(),
        email:row.email,
        mgvId: row.nummer,
        nameAg:row.aname,
        briefAnrede:row.anrede,
        status:"none",
        _address:{
          postal_code:row.mplz,
          locality:row.mort,
        },
        _addressAg:{
          throughfare:row.astrasse,
          postal_code:row.aplz,
          locality:row.aort,
        },
        tarifBindung:row.tarif, 
        delete:row.ausgetreten
      });
    }
  }}
  onComplete={async ({ file, preview, fields, columnFields }) => {
    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
    //showMyAppToastNotification();
    console.log(memberList);
    setMemberListState(memberList);
  }}
  onClose={async ({ file, preview, fields, columnFields }) =>{
    // optional, if this is specified the user will see a "Finish" button after import is done,
    // which will call this when clicked
   var result = await updateAllMembers({variables:{membersInput:memberListState}});
   setMemberListState(result.data.updateAllMembers.sort((a,b)=>{
    if(a.status === b.status)
    {
      return 0;
    }
    if(a.status === "error")
    {
      return -1;
    }
    if(b.status ==="error")
    {
      return 1;
    }
    return 0;
   }));
  }}

  // CSV options passed directly to PapaParse if specified:
   //delimiter={';'}
  // newline={...}
  // quoteChar={...}
  // escapeChar={...}
  // comments={...}
  // skipEmptyLines={...}
  // delimitersToGuess={...}
  // chunkSize={...} // defaults to 10000
   encoding={"iso-8859-2"} // defaults to utf-8, see FileReader API
>
  <ImporterField name="nummer" label="nummer" />
  <ImporterField name="vorname" label="vorname" />
  <ImporterField name="nachname" label="nachname" />
  <ImporterField name="geburtsdatum" label="geburtsdatum" />
  <ImporterField name="email" label="email" optional/>
  <ImporterField name="anrede" label="anrede" optional/>
  <ImporterField name="mplz" label="mplz" optional/>
  <ImporterField name="mort" label="mort" optional/>
  <ImporterField name="aname" label="aname" optional/>
  <ImporterField name="astrasse" label="astrasse" optional/>
  <ImporterField name="aplz" label="aplz" optional/>
  <ImporterField name="aort" label="aort" optional/>
  <ImporterField name="ausgetreten" label="ausgetreten" optional/>
  
  <ImporterField name="tarif" label="tarif" optional />
</Importer>
{loadingUpdateAllMembers &&
<div style={{width:"100%", display:"flex", justifyContent:"center", margin:"32px"}}>
  <Spin size='large'></Spin>
</div>}

<div>
  <Table pagination={true} columns={columns} dataSource={memberListState?memberListState:[]}></Table>
  
</div>
  </div>
    );
  
}

export default CsvUploadPage;
