import React, { useState, useContext } from 'react';
import {useQuery,  useLazyQuery, gql, useMutation } from '@apollo/client';
import { Form, Button, Input, Table, Icon, Checkbox, Alert } from 'antd';
import{UserOutlined, DeleteOutlined} from '@ant-design/icons'
import './Auth.css';
import {AuthContext} from '../context/auth-context';
import {BackendAddress} from './../Settings';
import moment from "moment";

const MailingPage =(props) =>{
 
  const {token, userId} = useContext(AuthContext);
  const authHeader = {context: {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }};
  
  const requestBodyMailings = gql `
  query Mailings
  {
    mailings
    {
      _id
      name
      description
      date
      _landesVerband
      {
        _id
        name
        number
      }
      _abfrage
      {
        name
        description
        definition
      }      
      _members{
        _id
        name
        firstName
        mgvId
        email
      }  
    }
  }`;

const deleteBodyMailings = gql `
mutation deleteMailings
{
  mailings
  {
    _id
    name
    description
    date
    _landesVerband
    {
      _id
      name
      number
    }
    _abfrage
    {
      name
      description
      definition
    }        
  }
}`;
   
   const {loading:loadingMailings, error:errorMailings, data: dataMailings} = useQuery(requestBodyMailings, authHeader); 
  
   const deleteMailingHandler = async (record)=>
  {
    console.log(record);
  }

  const mailingColumns = [
    {
      title: 'Name',
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: 'Beschreibung',
      dataIndex: ['description'],
      key: 'description',
    },
    {
      title: 'Landesverband',
      key: '_landesVerband',
      render: (record) => (
        <div>
          <p>{record._landesVerband.name}</p>
      </div>
      ),
    },
    {
      title: 'Abfrage',
      key: '_abfrage',
      render: (record) => (
        <div>
          <p>{record._abfrage.name}</p>
          <p>{record._abfrage.description}</p>
      </div>
      ),
    },
    {
      title: 'Datum',
      key: 'date',
      render: (record) => (
        <div>
          <p>{new moment(record.date).format("DD.MM.YYYY")}</p>         
      </div>
      ),
    },
    {
      title: 'Aktion',
      key: 'action',
      render: (record) => (
        <div>
        <Button onClick={(record)=>deleteMailingHandler(record)}>
          <DeleteOutlined></DeleteOutlined>
        </Button>
      </div>
      ),
    },
  ];
 
    return (
      <div style={{margin:"32px"}}>
        <Table columns={mailingColumns} dataSource={dataMailings?dataMailings.mailings:[]}></Table>
      </div>
    );
  
}

export default MailingPage;
