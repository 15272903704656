import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';


import AuthPage from './pages/Auth';
import TwoFactorPage from './pages/TwoFactor';
import StartPage from './pages/Start.js';
import SingleSearchPage from './pages/SingleSearch';
import MailingPage from './pages/Mailings';
import BerichtigungenPage from './pages/Berechtigungen'
import CsvUploadPage from './pages/CsvUpload'
import AdminPage from './pages/admin/Admin'
import UserProfilePage from './pages/UserProfile';
import UserSignUpPage from './pages/UserSignUp';
import UserVerifyPage from './pages/UserVerify';
import MainNavigation from './components/Navigation/MainNavigation';
import { AuthContext, CheckTokenValid } from './context/auth-context';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import FooterComponent from './components/Footer/footer'
import 'antd/dist/antd.less';
import { BackendAddress } from './Settings';

import './App.less';
import PasswordResetPage from './pages/PasswordReset';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
const client = new ApolloClient({
  uri: BackendAddress,
  cache: new InMemoryCache(),
  
  defaultOptions:defaultOptions
});

function App(props) {

  useEffect(() => {
    if (localStorage.getItem("jwt-mbz")) {
      CheckTokenValid(localStorage.getItem("jwt-mbz")).then(result => {
        console.log(result);
        if (result.valid === false) {
          logout();
        }
        else {
          login(localStorage.getItem("jwt-mbz"), result.userId, result.isAdmin, result.landesVerband, 2,result.twoFactor);
        }
      });


    } else {
      logout();
    }
  }, []);

  const login = (token, userId, isAdmin, landesVerband, tokenExpiration, twoFactor) => {
    console.log("login in App called")
    setState({ token: token, userId: userId, isAdmin: isAdmin, landesVerband:landesVerband, twoFactor:twoFactor});
    localStorage.setItem("jwt-mbz", token);
  };

  const logout = () => {
    setState({ token: null, userId: null, isAdmin: null, landesVerband:null, twoFactor:null });
    localStorage.removeItem("jwt-mbz");
  };

  const [state, setState] = useState({
    token: null,
    userId: null,
    isAdmin: null,
    twoFactor: null
  });

 

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <React.Fragment>
          <AuthContext.Provider
            value={{
              token: state.token,
              userId: state.userId,
              isAdmin: state.isAdmin,
              landesVerband:state.landesVerband,
              twoFactor:state.twoFactor,
              login: login,
              logout: logout
            }}
          >
            <MainNavigation key={props.key} />
            <div className="before"></div>
            <main className="main-content">
              <Routes>
             
                {!state.token && 
                <React.Fragment>
                  <Route path="/auth" element={<AuthPage/>} />
                  <Route path="/passwordReset/:token" element={<PasswordResetPage/>} />
                  <Route path="/passwordReset" element={<PasswordResetPage/>} />
                  <Route path="/*" element={<Navigate replace to="/auth"></Navigate>}/>
                </React.Fragment>
                }
                {
                  !state.twoFactor && state.token &&
                  <React.Fragment>
                    <Route path="/twoFactor" element={<TwoFactorPage></TwoFactorPage>}></Route>
                    <Route path="/auth" element={<Navigate replace to="/twoFactor"></Navigate>} />
                    <Route path ="/" element={<Navigate replace to="/twoFactor"></Navigate>}></Route>
                  </React.Fragment>
                }
               
                
                {state.token && state.twoFactor &&
                <React.Fragment>
                  <Route path="/"  element={<Navigate replace to="/start"></Navigate>}/>
                  <Route path="/auth" element={<Navigate replace to="/start"></Navigate>} />
                  <Route path="/twoFactor" element={<Navigate replace to="/start"></Navigate>} />
                </React.Fragment>
                }
           
           
            {state.token && state.twoFactor &&
              <React.Fragment>
                
                <Route path="/start"  element={<StartPage/>}></Route>
                <Route path="/singleSearch"  element={<SingleSearchPage/>}></Route>
                <Route path="/mailings"  element={<MailingPage/>}></Route>
                <Route path="/berechtigungen"  element={<BerichtigungenPage/>}></Route>
                <Route path="/csvUpload"  element={<CsvUploadPage/>}></Route>
                {state.isAdmin && 
                  <Route path="/admin"  element={<AdminPage/>}></Route>
                }
                <Route path="/profile" element={<UserProfilePage/>} />
                <Route path="/verify/:token" element={<UserVerifyPage/>} />
                
              </React.Fragment>
            }
            </Routes>
            </main>
            <FooterComponent></FooterComponent>
          </AuthContext.Provider>
        </React.Fragment>
      </BrowserRouter>
    </ApolloProvider>
  );
}


export default App;
