import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import './MainNavigation.css';
import logo from '../../resources/logo150px.png';
import { Menu, Icon, Row, Col } from 'antd';


const MainNavigation = props => {
  const { SubMenu } = Menu;



  const { token, logout, isAdmin, landesVerband, twoFactor } = useContext(AuthContext);
console.log(useContext(AuthContext));
  const [selectedMenu, selectedMenuChanged] = useState(props.key);

  const menuSelectHandler = (e) => {
    selectedMenuChanged(e.key);
  }

  return (
    <header className="main-navigation">
      <div className="main-navigation__wrapper">
        <div className="main-navigation__title">
          <Row>
            <div className="main-navigation__menu">
              <Col spa={4}>
                <a href="/start">
                  <img src={logo} style={{marginRight:"32px"}}></img>
                </a>
              </Col>
              <Col span={20}>
                <Row justify="end" align="middle" className="nav-row">
                  <Col span={21} style={{ marginBottom: "0px", display: "inline", textOverflow: "ellipsis", overflow: "hidden" }} >
                    <h1 style={{ marginBottom: "0px", display: "inline", whiteSpace: "nowrap", }} className="titletext-h1">MB Zentral Mailregister {token && " - "+landesVerband} </h1>
                  </Col>
                  <Col span={3}>
                    <Row justify="end">
                      <nav className="main-navigation__items">
                        <ul>
                          {!twoFactor && token &&
                          <li>
                           <button onClick={logout}>Zurück</button>
                          </li>
                          }
                          {!twoFactor && !token &&(
                            <li>
                              <NavLink to="/auth">Login</NavLink>
                              <NavLink to="/signup">Konto Erstellen</NavLink>
                            </li>
                          )}
                          {twoFactor && (
                            <React.Fragment>
                              <li>
                                <NavLink to="./Start">Start</NavLink>
                              </li>
                              <li>
                                <NavLink to="./profile">Profil</NavLink>
                              </li>
                              <li>
                                <button onClick={logout}>Logout</button>
                              </li>

                            </React.Fragment>
                          )}
                        </ul>
                      </nav>
                    </Row>
                  </Col>


                </Row>
                <Row justify="end">
                  <Col span={24}>
                    {twoFactor &&
                    <Menu style={{ width: "100%" }} mode="horizontal" onSelect={menuSelectHandler.bind(null)} selectedKeys={selectedMenu}>
                      <Menu.Item key="start"><NavLink className="smalltext-standard" to="./start">Querysuche</NavLink> </Menu.Item>
                      <Menu.Item key="singleSearch"><NavLink className="smalltext-standard" to="./singleSearch">Einzelsuche</NavLink> </Menu.Item>
                      <Menu.Item key="mailings"><NavLink className="smalltext-standard" to="./mailings">Mailings</NavLink> </Menu.Item>
                      <Menu.Item key="berechtigungen"><NavLink className="smalltext-standard" to="./berechtigungen">Berechtigungen</NavLink> </Menu.Item>
                      <Menu.Item key="csvUpload"><NavLink className="smalltext-standard" to="./csvUpload">Mitglieder Upload</NavLink> </Menu.Item>
                      <Menu.Item key="admin"><NavLink className="smalltext-standard" to="./admin">Admin</NavLink> </Menu.Item>
                      
                      <Menu.Item key="about"><NavLink className="smalltext-standard" to="./about">Über uns</NavLink></Menu.Item>
                    </Menu>}
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </div>


      </div>
    </header>
  );
}


export default MainNavigation;
