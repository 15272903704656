import React, { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';
import './Start.css';
import './../components/global.less';
import { Alert, Row, Col, Button, Table, Modal, Divider, Spin, DatePicker, Input, Select } from 'antd';

import moment from 'moment';

const SingleSearchPage = props => {

    const tarifBindungRef = useRef("");
    const nameAgRef = useRef("");
    const nameRef = useRef("");
    const emailRef = useRef("");
    const firstNameRef = useRef("");
    const mgvIdRef = useRef("");
    const plzRef = useRef("");
    const geburtsDatumRef = useRef("");
    const landesverbandRef = useRef("");

    const [infoTextState, setInfoTextState] = useState("");

    const { token, userId } = useContext(AuthContext);
    const authHeader = {
        context: {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    };

    const queryMembers = gql`
        query MemberLookup($memberInput:MemberInput! $landesVerbandId:ID) {
            memberLookup(memberInput:$memberInput landesVerbandId:$landesVerbandId)
            {
                _id
                name   
                firstName
                birthDate
                email
                _address{
                    locality
                    postal_code
                    country
                }   
                _landesVerband{
                    name
                } 
                mgvId 
                nameAg
                tarifBindung
            }
        }
    `;

    const queryLandesverbaende = gql`
        query Verbaende{
            landesVerbaende
            {
                _id
                name   
            }
        }
    `;

    const [searchMemberQuery, { data: dataSearchMemberQuery, loading: loadingSearchMemberQuery, error: errorSearchMemberQuery }] = useLazyQuery(queryMembers, authHeader);
    const { data: dataSearchLandesverbandQuery, loading: loadingLandesverbandQuery, error: errorLandesverbandQuery } = useQuery(queryLandesverbaende, authHeader);

    const searchClickHandler = async (event) => {
        if (nameRef.current.length + plzRef.current.length + geburtsDatumRef.current.length 
            + mgvIdRef.current.length + emailRef.current.length + nameAgRef.current.length 
            + tarifBindungRef.current.length + firstNameRef.current.length ) {
            setInfoTextState("");
            searchMemberQuery({
                variables:
                {
                    memberInput:
                    {
                        mgvId: mgvIdRef.current,
                        name: nameRef.current,
                        firstName: firstNameRef.current,
                        _address: {
                            postal_code: plzRef.current
                        },
                        birthDate: geburtsDatumRef.current,
                        email: emailRef.current,
                        nameAg: nameAgRef.current,
                        tarifBindung: tarifBindungRef.current
                    },
                    landesVerbandId: landesverbandRef.current
                }
            })
        }
        else {
            setInfoTextState("Sie müssen mindestens ein Suchfeld ausfüllen.");
        }
    }

    const inputChanged = async (key, value) => {
        switch (key) {
            case "name":
                nameRef.current = value;
                break;
            case "nameAg":
                nameAgRef.current = value;
                break;
            case "tarifBindung":
                tarifBindungRef.current = value;
                break;
            case "firstName":
                firstNameRef.current = value;
                break;
            case "plz":
                plzRef.current = value;
                break;
            case "landesverband":
                landesverbandRef.current = value;
                break;
            case "mgvId":
                mgvIdRef.current = value;
                break;
            case "email":
                emailRef.current = value;
                break;
            case "geburtsdatum":
                geburtsDatumRef.current = value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") + "Z" : "";
                break;
            default:
                break;
        }
    }

    const keyPressed = async (e) => {
        if (e.key == "Enter") {
            searchClickHandler();
        }
    }
    const sortLv = (lv1, lv2) => { return lv1.label > lv2.label ? 1 : -1; }

    const columns = [
        {
            title: "Vorname",
            dataIndex: 'firstName',
            key: "firstName",
            render: (record) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{record}</p>
        },
        {
            title: "Nachname",
            dataIndex: 'name',
            key: "name",
            render: (record) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{record}</p>
        },
        {
            title: "Mitgliedsnummer",
            dataIndex: ['mgvId'],
            key: "mgvId"
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: "email",
            render: (record) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{record}</p>
        },
        {
            title: "Geb.Datum",
            dataIndex: 'birthDate',
            key: "birthDate",
            render: (gebDat) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{moment(gebDat).format("DD.MM.YYYY")}</p>
        },
        {
            title: "Adresse",
            dataIndex: "_address",
            key: "_address",
            render: (address) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{address.postal_code} {address.locality}</p>
        },
        {
            title: "Landesverband",
            dataIndex: ['_landesVerband', 'name'],
            key: "_landesVerband"
        },
        {
            title: "Arbeitgeber",
            dataIndex: 'nameAg',
            key: "nameAg"
        },
        {
            title: "Tarifbindung",
            dataIndex: 'tarifBindung',
            key: "tarifBindung"
        },
    ];

    return (
        <div style={{ margin: "16px auto", width: "90vw" }}>
            {infoTextState &&
                <Row style={{ marginBottom: "16px", width: "100%" }}>
                    <Alert style={{ width: "100%" }} type="warning" message={infoTextState}></Alert>
                </Row>
            }
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col>
                    <p className='smalltext-standard'>Nummer:</p>
                    <Input placeholder='Mitgl.Nr.' onChange={(event) => inputChanged("mgvId", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Vorname:</p>
                    <Input placeholder='Vorname' onChange={(event) => inputChanged("firstName", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Name:</p>
                    <Input placeholder='Name' onChange={(event) => inputChanged("name", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <p className='smalltext-standard'>E-Mail:</p>
                    <Input placeholder='Mailadresse' onChange={(event) => inputChanged("email", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Plz:</p>
                    <Input placeholder='PLZ' onChange={(event) => inputChanged("plz", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Geb.Datum:</p>
                    <DatePicker format='DD.MM.YYYY' type='date' placeholder='Datum' onChange={(event) => inputChanged("geburtsdatum", event)}></DatePicker>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Landesverband:</p>
                    <Select style={{ minWidth: '150px', width: '100%' }}
                        onChange={(e) => inputChanged("landesverband", e)}
                        options={dataSearchLandesverbandQuery ? [{ value: "", label: "Alle" }, ...dataSearchLandesverbandQuery.landesVerbaende.map((lv) => { return { value: lv._id, label: lv.name } }).sort(sortLv)] : []}>
                    </Select>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Arbeitgeber-Name:</p>
                    <Input placeholder='Arbeitgeber' onChange={(event) => inputChanged("nameAg", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <p className='smalltext-standard'>Tarifbindung:</p>
                    <Input placeholder='Tarifbindung' onChange={(event) => inputChanged("tarifBindung", event.target.value)} onKeyDown={(e) => { keyPressed(e) }}></Input>
                </Col>
                <Col>
                    <div style={{ display: 'flex', height: "100%", alignItems: "end" }}>
                        <Button onClick={() => searchClickHandler()}>Suche</Button>
                    </div>
                </Col>
            </Row>
            <Row style={{ width: "100%" }}>
                <Table style={{ width: "100%" }} rowKey={(record) => record._id} columns={columns} dataSource={dataSearchMemberQuery ? dataSearchMemberQuery.memberLookup : []}>

                </Table>
            </Row>
        </div>
    )
}

export default SingleSearchPage;