
import {Button, Table, Checkbox, Row, Col, Alert, Modal, Input, Form} from 'antd'

import React, { useState, useContext, useEffect } from 'react';
import {AuthContext} from '../../context/auth-context';
import {BackendAddress} from './../../Settings';



const TwoFactorModal = (props) =>{

    const context = useContext(AuthContext);
    const [form] = Form.useForm();
    const [errorState, setErrorState] = useState();


    useEffect(() => {
        resendMail();
    },
        []);

    const resendMail = event => {
    

        let requestBody = {
          query: `
            query TwoFactorRequest {
              twoFactorRequest 
            }
          `
        };
    
        
    
        fetch(BackendAddress, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + context.token
          }
        })
          .then(res => {
            if (res.status !== 200 && res.status !== 201) {
              throw new Error('Failed!');
            }
            return res.json();
          })
          .catch(err => {
            console.log(err);
          });
      };
    
    
      const submitHandler = event => {
        event.preventDefault();
        const code = form.getFieldValue("code");
        
    
        if (code.trim().length === 0) {
          return;
        }
    
        let requestBody = {
          query: `
            query TwoFactorCheck($code:String!) {
              twoFactorCheck(code:$code) {
                userId
                token
                tokenExpiration
                landesVerband
                isAdmin
                frontendPermissions{
                  name
                }
                twoFactor
              }
            }
          `,
          variables: {
            code:code,
          }
        };
    
       
    
        fetch(BackendAddress, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + context.token,
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            if (res.status !== 200 && res.status !== 201) {
              throw new Error('Failed!');
            }
            return res.json();
          })
          .then(resData => {
            if(!resData.data && resData.errors)
            {
              setErrorState(resData.errors[0].message);
            }
            if (resData.data.twoFactorCheck.twoFactor) {
              props.callback();
            }
            console.log(context);
          })
          .catch(err => {
            console.log(err);
          });
      };
    

    return (
    <Modal visible={props.visible} footer={null} onCancel={()=>{props.onCancel()}}>
        <Form className="twofactor-form" layout="vertical" form={form}>
        {errorState && 
        <Form.Item><Alert message={errorState} type="error"></Alert></Form.Item>
        }
        <Form.Item>
          <div>
            Wir haben einen Code zur Bestätigung an Ihre E-Mail Addresse geschickt:
          </div>
        </Form.Item>
          <Form.Item name="code" rules={[{ required: true, message: 'Bitte geben Sie den Code ein!' }]}>
              <Input
                placeholder="code"
              />
          </Form.Item>
          <Form.Item valuePropName="resend" initialValue={true}>
            <Button type="link" onClick={resendMail}>
              erneut senden
            </Button>
            </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" onClick={submitHandler.bind(null)} >
              Code Prüfen
            </Button>
            
          </Form.Item>
            
        
        </Form>
    </Modal>)
}

export default TwoFactorModal;