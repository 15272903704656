import React, { useState, useContext, useEffect, useRef } from 'react';
import {Button, Table, Checkbox, Row, Col, Tabs, Modal, Input, Select} from 'antd'

import {AuthContext} from '../../context/auth-context';
import { useQuery, useLazyQuery, useMutation,gql } from '@apollo/client';
import{SaveOutlined, PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import RoleEdit from './../../components/admin/RoleEdit';
import {updateRoleMutation} from './../../queries/roleQueries';
import moment from 'moment'


const { TabPane } = Tabs;
const { Option } = Select;


const AdminPage = (props) =>{

    
    const {token, userId, isAdmin} = useContext(AuthContext);

    const [showUserModalState, setShowUserModalState] = useState();
    const [showRoleModalState, setShowRoleModalState] = useState();
    const currentRolesRef = useRef();
    const newRoleNameRef = useRef();
    const currentUsersRef = useRef();
    const editUserRef = useRef();

    const authHeader = {context: {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }};


    const requestBodyUsers = gql `
    query Users {
        users {
            _id
            name
            firstName
            email
            _landesVerband
            {
                name
                number
            }
            blocked
            _roles{
                _id
                name
                entityPermissions
                {
                    entity
                    {
                        name
                    }
                    privileges
                    {
                        read
                        write
                        delete
                    }
                }
            }
        }
    },
    `;
    const updateBodyUser = gql `
    mutation UpdateUser($userInput:UserInput) {
        updateUser(userInput:$userInput) {
            _id
            name
            firstName
            email
            _landesVerband
            {
                name
                number
            }
            blocked
            _roles{
                name
                entityPermissions
                {
                    entity
                    {
                        name
                    }
                    privileges
                    {
                        read
                        write
                        delete
                    }
                }
            }
        }
    },
    `;

const requestBodyRoles = gql `
query Roles {
    roles {
        _id
        name
        entityPermissions{
            entity{
                _id
                name
            }
            privileges{
                read
                write
                delete
            }
        }
    }
},`;


const requestBodyCsvLogs = gql `
query CsvLogs {
    csvLogs {
        _id
        _user{
            _id
            firstName
            name
        }
        _members{
            _id
            firstName
            name
            mgvId
        }
        date
    }
},`;


    const {loading:loadingUsers, error:errorUsers, data:dataUsers, refetch:refetchUsers}= useQuery(requestBodyUsers,authHeader);
    const {loading:loadingRoles, error:errorRoles, data:dataRoles, refetch:refetchRoles}= useQuery(requestBodyRoles,authHeader);
    const {loading:loadingCsvLogs, error:errorCsvLoags, data:dataCsvLogs, refetch:refetchCsvLogs}= useQuery(requestBodyCsvLogs,authHeader);
    const [updateUserMutation,{loading:loadingUpdateUser, error:errorUpdateUser, data:dataUpdateUser}]= useMutation(updateBodyUser,authHeader);
    const [updateRole,{loading:loadingUpdateRole, error:errorUpdateRole, data:dataUpdateRole}]= useMutation(updateRoleMutation,authHeader);
   
    useEffect ( () =>{
      if(dataUsers)
      {
        currentUsersRef.current = dataUsers.users;
      }
      if(dataRoles)
      {
        currentRolesRef.current = dataRoles.roles.map(role =>{
            return role
        });
      }
    },[dataUsers, dataRoles]);
 
    const roleSelectHandler = async(values)=>
    {
        editUserRef.current._roles = dataRoles.roles.filter(x=>values.includes(x._id));
       console.log(editUserRef.current._roles);
    }

    const openUserRolesEditModal = async(user)=>
    {
        editUserRef.current = {...user};
        setShowUserModalState(true);
    }

    const cancelUserRoleEditModalHandler = async ()=>{
        editUserRef.current = undefined;
        setShowUserModalState(false);
    }

    const updateEditUserHandler = async (user)=>
    {
        var res = await updateUserMutation({
            variables:{
                userInput:{                         
                        id:user._id, 
                        _roles:user._roles.map((role)=>{return {id:role._id}})
                }
            }
        });
        refetchUsers();
        setShowUserModalState(false);
    }

    const addRoleHandler = async ()=>{
        var res = await updateRole({variables:{roleInput:
        {
            name:newRoleNameRef.current,
        }}});
        refetchRoles();
        refetchUsers();
        setShowRoleModalState(false);
    }

    const refreshRoles = asnyc =>{
        refetchRoles();
    }
    
    const userColumns=[
        {
            title: 'Vorname',
            dataIndex: ['firstName'],
            key: 'firstName',
          },
        {
            title: 'Name',
            dataIndex: ['name'],
            key: 'name',
          },
        {
            title: 'E-Mail',
            dataIndex: ['email'],
            key: 'email',
          },
          {
            title: 'blocked',
            key: 'blocked',
            render: (record) => (
              <div>
               <Checkbox checked={record.blocked}></Checkbox>
            </div>
            ),
          },
    ];
    const csvLogColumns=[
        {
            title: 'Nutzer',
            key: 'user',
            render: (record) =>(
                <p>{record._user.firstName} {record._user.name}</p>
            )
          },
        {
            title: 'Datum',
            dataIndex: ['date'],
            key: 'date',
            render:(record)=>(<p style={{ marginTop: "auto", marginBottom: "auto" }}>{moment(record).format("DD.MM.YYYY - hh:mm")} Uhr</p>),
          },
    ];
   
   
   

    return (
        <div className='pageContainer'>
            <Tabs defaultActiveKey="1" style={{margin:"32px"}}>
                <TabPane tab="User" key="1">
                    <Table dataSource={dataUsers ? dataUsers.users:[]} columns={userColumns} rowKey="_id"
                    expandable={{
                    expandedRowRender: (record) =>{return (
                        <div>
                            <Row><p> Rollen: </p> <Button onClick={()=>{openUserRolesEditModal(record)}}><EditOutlined></EditOutlined></Button></Row>
                            <Row>{record._roles.map(role => {return <p>{ role.name}, </p>})}</Row>
                        </div>)}}}>
                    </Table>
                </TabPane>
                <TabPane tab="Roles" key="2">
                    <Row gutter={[16,16]}>
                    {dataRoles &&
                        dataRoles.roles.map(role=>{return(
                            
                            <Col>
                                <RoleEdit role={role} refreshRoles={refreshRoles}></RoleEdit>
                            </Col>
                            
                             )})
                    }
                    <Button onClick={()=>{ setShowRoleModalState(true)}}><PlusOutlined ></PlusOutlined></Button>
                    </Row>
                </TabPane>
                <TabPane tab="CsvLogs" key="3">
                    {dataCsvLogs &&
                    <Table dataSource={dataCsvLogs.csvLogs} columns={csvLogColumns} rowKey="_id"
                    expandable={{
                        expandedRowRender: (record) =>{return (
                            <div>
                                <Row><p><strong> Exportierte Mitglieder:</strong> </p> </Row>
                                <Row>{record._members.map(member => {return <p style={{marginRight:"3px"}}>{member.firstName} {member.name} &#040;{member.mgvId}&#041;,</p>})}</Row>
                            </div>)}}}>
                    </Table>

                    }
                </TabPane>
            </Tabs>
            {editUserRef.current && dataRoles &&
            <Modal key="userModal" visible={showUserModalState} onCancel={()=>{cancelUserRoleEditModalHandler()}} onOk={()=>{updateEditUserHandler(editUserRef.current)}}>
            <Select
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Please select"
                defaultValue={editUserRef.current._roles.map(role=>{return role._id})}
                onChange={(e)=>{roleSelectHandler(e)}}
                >
                {dataRoles.roles.map(role=>{
                    return (<Option key={role._id} value={role._id}>{role.name}</Option>)
                })}
                </Select>
            </Modal>
}
            <Modal key="roleModal" visible={showRoleModalState} onCancel={()=>{setShowRoleModalState(false)}} onOk={()=>{addRoleHandler()}}>
                    <h2>Name</h2>
                    <Input placeholder='Name' onBlur={(e)=>{newRoleNameRef.current = e.target.value}}></Input>
            </Modal>
        
    </div>
    );
    
}

export default AdminPage;
