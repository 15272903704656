import React, { useState, useRef, useEffect, useContext } from 'react';
import de_DE from 'antd/lib/locale-provider/de_DE';
import { AuthContext } from '../context/auth-context';
import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';
import './Start.css';
import './../components/global.less';
import 'antd/dist/antd.less';
import { Query, Builder, BasicConfig, Utils, BuilderProps } from 'react-awesome-query-builder';
import AntdWidgets from 'react-awesome-query-builder/lib/components/widgets/antd';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import { Row, Col, Button, Table, Modal, Divider, Spin, DatePicker, Input } from 'antd';
import { SaveOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CSVLink } from "react-csv";
import ScrollingLock from 'react-scrolling-lock';
import TwoFactorModal from '../components/Modal/twoFactorModal';

import 'react-awesome-query-builder/lib/css/styles.css';
//import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
const { queryBuilderFormat, jsonLogicFormat, queryString, mongodbFormat, sqlFormat, getTree, checkTree, loadTree, uuid, loadFromJsonLogic } = Utils;
const InitialConfig = AntdConfig;
const { TextArea } = Input;

const config = {
  ...InitialConfig,
  settings: {
    ...AntdConfig.settings,
    locale: {
      antd: de_DE,
    },
    valueLabel: "Wert",
    valuePlaceholder: "Wert",
    fieldLabel: "Feld",
    operatorLabel: "Operator",
    fieldPlaceholder: "Feld wählen",
    operatorPlaceholder: "Operator wählen",
    deleteLabel: null,
    addGroupLabel: "Gruppe hinzufügen",
    addRuleLabel: "Regel hinzufügen",
    addSubRuleLabel: "Unterregel hinzufügen",
    delGroupLabel: null,
    notLabel: "Nicht",
  },
  conjunctions: {
    ...AntdConfig.conjunctions,
    AND:
    {
      ...InitialConfig.conjunctions.AND,
      label: 'Und'
    },
    OR:
    {
      ...InitialConfig.conjunctions.OR,
      label: "Oder"
    },
  },
  widgets: {
    ...AntdConfig.widgets,
    multiselect: {
      ...AntdConfig.widgets.multiselect,
      customProps:
      {
        showArrow: true,
        showSearch: true,
      },
    },
  },
  operators: {
    ...AntdConfig.operators,
    equal: {
      ...AntdConfig.operators.equal,
      label: "gleich"
    },
    not_equal: {
      ...AntdConfig.operators.not_equal,
      label: "nicht gleich"
    },
    is_empty: {
      ...AntdConfig.operators.is_empty,
      label: "leer"
    },
    is_not_empty: {
      ...AntdConfig.operators.is_not_empty,
      label: "nicht leer"
    },
    like: {
      ...AntdConfig.operators.like,
      label: "ähnlich"
    },
    not_like: {
      ...AntdConfig.operators.not_like,
      label: "nicht ähnlich"
    },
    starts_with: {
      ...AntdConfig.operators.starts_with,
      label: "beginnt mit"
    },
    ends_with: {
      ...AntdConfig.operators.ends_with,
      label: "endet mit"
    },
    multiselect_equals: {
      ...AntdConfig.operators.multiselect_equals,
      label: "gleich"
    },
    multiselect_not_equals: {
      ...AntdConfig.operators.multiselect_not_equals,
      label: "nicht gleich"
    },
    select_equals: {
      ...AntdConfig.operators.select_equals,
      label: "gleich"
    },
    select_not_equals: {
      ...AntdConfig.operators.select_not_equals,
      label: "nicht gleich"
    },
    between: {
      ...AntdConfig.operators.between,
      label: "zwischen"
    },
    not_between: {
      ...AntdConfig.operators.not_between,
      label: "nicht zwischen"
    },
    is_null: {
      ...AntdConfig.operators.is_null,
      label: "nicht definiert"
    },
    is_not_null: {
      ...AntdConfig.operators.is_not_null,
      label: "definiert"
    },


  },
  fields: {
    name: {
      label: 'Nachname',
      type: 'text',
      valueSources: ['value'],
      excludeOperators: ["proximity"],
      preferWidgets: ['text'],
    },
    firstName: {
      label: 'Vorname',
      type: 'text',
      valueSources: ['value'],
      excludeOperators: ["proximity"],
      preferWidgets: ['text'],
    },
    birthDate: {
      label: 'Geburtstag',
      type: 'date',
      valueSources: ['value'],
      preferWidgets: ['date'],
    },
    email: {
      label: 'E-Mail',
      type: 'text',
      valueSources: ['value'],
      excludeOperators: ["proximity"],
      preferWidgets: ['text'],
    },
    _address: {
      type: '!struct', // special keyword for comlex fields
      label: 'Adresse',
      subfields: {
        // subfields of complex field
        postal_code: {
          label: 'Plz',
          type: 'text',
          valueSources: ['value'],
          excludeOperators: ["proximity"],
          preferWidgets: ['text'],
        },
        locality: {
          label: 'Stadt',
          type: 'text',
          valueSources: ['value'],
          excludeOperators: ["proximity"],
          preferWidgets: ['text'],
        },
      },
    },
    _addressAg: {
      type: '!struct', // special keyword for comlex fields
      label: 'Adresse-Arbeitgeber',
      subfields: {
        // subfields of complex field
        postal_code: {
          label: 'Plz',
          type: 'text',
          valueSources: ['value'],
          excludeOperators: ["proximity"],
          preferWidgets: ['text'],
        },
      },
    },
    mgvId: {
      label: 'Mitgliedsnummer',
      type: 'text',
      valueSources: ['value'],
      excludeOperators: ["proximity"],
      preferWidgets: ['text'],
    },
    // tarif: {
    //   label: 'Beitragsgruppe',
    //   type: 'multiselect',
    //   valueSources: ['value'],
    //   preferWidgets: ['multiselect'],
    //   fieldSettings: {
    //     listValues: ["Normal", "Student"],
    //   },   
    // },
    nameAg: {
      label: 'Arbeitgebername',
      type: 'text',
      excludeOperators: ["proximity"],
      valueSources: ['value'],
      preferWidgets: ['text'],
    },
    tarifBindung: {
      label: 'Tarifbindung',
      type: 'text',
      excludeOperators: ["proximity"],
      valueSources: ['value'],
      preferWidgets: ['text'],
    },
    _landesVerband: {
      label: 'Landesverband',
      type: 'multiselect',
      fieldSettings: {
        listValues: ["test", "test2"],
      },
      valueSources: ['value'],
      preferWidgets: ['multiselect'],
    },
    _landKreis: {
      label: 'Landkreis',
      type: 'treemultiselect',
      fieldSettings: {
        treeValues: [],
      },
      valueSources: ['value'],
    },
  }
};

const StartPage = props => {



  const { token, userId } = useContext(AuthContext);
  const [configState, setConfigState] = useState();
  const [treeState, setTreeState] = useState({});
  const [abfragenState, setAbfragenState] = useState();
  const [currentAbfrageState, setCurrentAbfrageState] = useState();
  const [showModalMailingState, setShowModalMailingState] = useState(false);
  const [showModalState, setShowModalState] = useState(false);
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const [showCsvDownloadButton, setShowCsvDownloadButton] = useState(false);
  const [currentResultState, setCurrentResultState] = useState([]);

  const newAbfrageNameRef = useRef();
  const newAbfrageDescriptionRef = useRef();
  const newAbfrageDefinitionRef = useRef();
  const currentAbfrageIdRef = useRef();
  const configRef = useRef();
  const mongoQueryRef = useRef();

  const abfragenRef = useRef();
  const currentAbfrageRef = useRef();
  const currentMailingRef = useRef();
  const [renderState, setRenderState] = useState(false);

  const queryLandesverbaende = gql`
  query LandesVerbaende{
          landesVerbaende
          {
              _id
              name
              number
              _landKreise
              {
                  _id
                  name
                  _localities
                  {
                    _id
                    postal_code
                    locality
                  }
              }
              _berechtigungen{
                _id
                _landKreise
                {
                  _id
                  name
                }
              }
          }
      }
  `;

  const queryLandesverband = gql`
  query LandesVerband{
          landesVerband
          {
              _id
              name
              number
              _landKreise
              {
                  _id
                  name
                  _localities
                  {
                    _id
                    postal_code
                    locality
                  }
              }
              _berechtigungen{
                _id
                _landesVerbandCreator
                {
                  _id
                  name
                }
                _landKreise
                {
                  _id
                  name
                }
              }
          }
      }
  `;

  const queryAbfragen = gql`
query Abfragen{
        abfragen
        {
          _id
          name         
          description
          definition           
        }
    }
`;

  const queryMembers = gql`
mutation MemberQuery($query:String!){
      memberQuery(query:$query)
        {
          _id
          name   
          firstName
          birthDate
          email
          mgvId
          lastUpdated
          _address{
            locality
            postal_code
            country
          }   
          _addressAg{
            locality
            postal_code
            throughfare
            country
          }   
          _landKreis{
            name
          } 
          _landesVerband{
            name
          }   
          nameAg
          tarifBindung
        }
    }
`;
  const mutationAbfrage = gql`
mutation UpdateAbfrage($id:ID $name:String! $description:String! $definition:String!){
  updateAbfrage(abfrageInput:{id:$id name:$name description:$description definition:$definition})
        {
          _id
          name
          _landesVerband{
            _id
            name
          }
          description
          definition            
        }
    }
`;

  const mutationMailingBody = gql`
mutation UpdateMailing($mailingInput:MailingInput!){
  updateMailing(mailingInput:$mailingInput)
        {
          _id
          name
          description            
        }
    }
`;

  const deleteAbfrageBody = gql`
mutation DeleteAbfrage($abfrageInput:AbfrageInput!){
  deleteAbfrage(abfrageInput:$abfrageInput)
        {
          _id
          name
          description            
        }
    }
`;

  const mutationCsvLogBody = gql`
mutation UpdateCsvLog($csvLogInput:CsvLogInput!){
  updateCsvLog(csvLogInput:$csvLogInput)
        {
          _user
          {
            _id
            name
            email
          }
          _members{
            _id
          }
        }
    }
`;





  const authHeader = {
    context: {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  };

  const { loading: loadingLandesVerbaende, error: errorLandesVerbaende, data: dataLandesVerbaende } = useQuery(queryLandesverbaende, authHeader);
  const { loading: loadingLandesVerband, error: errorLandesVerband, data: dataLandesVerband } = useQuery(queryLandesverband, authHeader);
  const { loading: loadingAbfragen, error: errorAbfragen, data: dataAbfragen } = useQuery(queryAbfragen, authHeader);
  const [updateAbfrage, { data: dataUpdateAbfrage, loading: loadingUpdateAbfrage, error: errorUpdateAbfrage }] = useMutation(mutationAbfrage, authHeader);
  const [updateMailing, { data: dataUpdateMailing, loading: loadingUpdateMailing, error: errorUpdateMailing }] = useMutation(mutationMailingBody, authHeader);
  const [updateCsvLog, { data: dataUpdateCsvLog, loading: loadingUpdateCsvLog, error: errorUpdateCsvLog }] = useMutation(mutationCsvLogBody, authHeader);
  const [deleteAbfrage, { data: dataDeleteAbfrage, loading: loadingdeleteAbfrage, error: errordeleteAbfrage }] = useMutation(deleteAbfrageBody, authHeader);
  const [sendQuery, { data: dataSendQuery, loading: loadingSendQuery, error: errorSendQuery }] = useMutation(queryMembers, authHeader);

  useEffect(() => {
    console.log("effect")
    // if(!abfragenState)
    // {
    //   setAbfragenState([]);
    // }
    if (!configRef.current) {
      configRef.current = config;
    }
    if (dataLandesVerbaende && dataLandesVerband && dataAbfragen) {
      configRef.current.fields._landesVerband.fieldSettings.listValues = dataLandesVerbaende.landesVerbaende.map(verband => { return { value: verband._id, title: verband.name } })
      let landKreise = [];
      landKreise.push({ value: dataLandesVerband.landesVerband._id, title: dataLandesVerband.landesVerband.name });
      landKreise.push(...dataLandesVerband.landesVerband._landKreise.map(kreis => {
        return { value: kreis._id, title: kreis.name, parent: dataLandesVerband.landesVerband._id };
      }));
      for (const berechtigung of dataLandesVerband.landesVerband._berechtigungen) {
        landKreise.push({ value: berechtigung._landesVerbandCreator._id, title: berechtigung._landesVerbandCreator.name });
        landKreise.push(...berechtigung._landKreise.map(kreis => {
          return { value: kreis._id, title: kreis.name, parent: berechtigung._landesVerbandCreator._id };
        }));
      }
      configRef.current.fields._landKreis.fieldSettings.listValues = landKreise;
      console.log(config);
      var abfragen = [];
      if (!abfragenRef.current) {
        for (const abfrage of dataAbfragen.abfragen) {
          abfragen.push({ ...abfrage, definition: checkTree(loadTree(JSON.parse(abfrage.definition)), configRef.current) });
        }
        abfragenRef.current = abfragen;
      }
      if (dataSendQuery) {
        // console.log(dataSendQuery.memberQuery);
        // currentAbfrageRef.current = {...currentAbfrageRef.current, result:dataSendQuery.memberQuery};
        // setRenderState(!renderState);
      }
      setConfigState(config);

    }

  }, [dataLandesVerbaende, dataLandesVerband, dataAbfragen, dataUpdateAbfrage, dataSendQuery])

  const handleQueryChanged = async (immutableTree, v) => {
    if (!currentAbfrageRef.current.name.includes('*') && immutableTree !== currentAbfrageRef.current.definition) {
      console.log(newAbfrageDefinitionRef.current);
      console.log(currentAbfrageRef.current.definition);
      currentAbfrageRef.current.name = currentAbfrageRef.current.name + "*";
      abfragenRef.current[abfragenRef.current.indexOf(abfragenRef.current.find(x => x._id === currentAbfrageRef.current._id))] = { ...currentAbfrageRef.current, definition: newAbfrageDefinitionRef.current };
      console.log(currentAbfrageRef.current);
      setRenderState(!renderState);
    }
    currentAbfrageRef.current = { ...currentAbfrageRef.current, definition: immutableTree }
  };


  const handleNewAbfrage = () => {
    abfragenRef.current = [{ name: "Neue Abfrage*", description: "Beschreibung", _id: abfragenRef.current.length + 1, definition: checkTree(loadTree({ "id": uuid(), "type": "group" }), configRef.current) },
    ...abfragenRef.current];
    //setAbfragenState([...abfragenState,{name:"Neue Abfrage*", description:"Beschreibung",_id:abfragenState.length+1, definition:checkTree(loadTree({"id": uuid(), "type": "group"}),configRef.current)}]);
    currentAbfrageRef.current = abfragenRef.current[0];
    console.log(abfragenRef.current);
    setRenderState(!renderState);
  };

  const handleAbfrageSelect = async (e) => {
    console.log(currentAbfrageRef.current);
    if (currentAbfrageRef.current) {
      abfragenRef.current[abfragenRef.current.indexOf(abfragenRef.current.find(x => x._id === currentAbfrageRef.current._id))] = { ...currentAbfrageRef.current };
    }

    currentAbfrageRef.current = { ...e.abfrage };
    console.log(e);
    console.log(currentAbfrageRef.current);
    console.log(abfragenRef.current);
    setRenderState(!renderState);
  };

  const handleAbfrageEdit = () => {
    newAbfrageNameRef.current = currentAbfrageRef.current.name;
    newAbfrageDescriptionRef.current = currentAbfrageRef.current.description;
    setShowModalState(true);
  }

  const handleSaveAll = () => {
    for (const abfrage in abfragenRef.current) {
      handleQuerySave(abfragenRef.current[abfrage])
    }
  };

  const handleQuerySave = async (abfrage) => {
    console.log(abfrage);
    const vars = {};
    if (abfrage._id.length > 3) {
      vars["id"] = abfrage._id;
    }
    vars["name"] = abfrage.name.replace('*', '');
    vars["description"] = abfrage.description;
    vars["definition"] = JSON.stringify(getTree(abfrage.definition, false));
    var res = await updateAbfrage({ variables: vars });
    console.log(res);
    if (res.data.updateAbfrage) {

      var updatedAbfrage = { ...res.data.updateAbfrage, definition: checkTree(loadTree(JSON.parse(res.data.updateAbfrage.definition)), configRef.current) }
      abfragenRef.current[abfragenRef.current.indexOf(abfragenRef.current.find(x => x._id === currentAbfrageRef.current._id))] = updatedAbfrage;

      currentAbfrageRef.current = updatedAbfrage;
      setRenderState(!renderState);
    }
    else if (res.error) {
      alert("Fehler beim Speichern der Abfrage");
    }
  };

  const handleModalOk = () => {
    abfragenRef.current[abfragenRef.current.indexOf(abfragenRef.current.find(x => x._id === currentAbfrageRef.current._id))] = { ...currentAbfrageRef.current, name: newAbfrageNameRef.current + "*", description: newAbfrageDescriptionRef.current };
    currentAbfrageRef.current = { ...currentAbfrageRef.current, name: newAbfrageNameRef.current + "*", description: newAbfrageDescriptionRef.current };
    setShowModalState(false);
  }

  const handleCurrentNameChange = (event) => {
    console.log(event.target.value);
    newAbfrageNameRef.current = event.target.value;
  };
  const handleCurrentDescriptionChange = (event) => {
    newAbfrageDescriptionRef.current = event.target.value;
  };

  const deleteAbfrageHaendler = () => {

  };

  const currentMailingChangeHandler = async (key, value) => {
    if (!currentMailingRef.current) {
      currentMailingRef.current = {};
    }
    switch (key) {
      case "name":
        currentMailingRef.current.name = value;
        break;
      case "description":
        currentMailingRef.current.description = value;
        break;
      default:
        break;
    }
  }

  const handleModalMailingOk = async () => {

    var res = await updateMailing({
      variables: {
        mailingInput:
        {
          name: currentMailingRef.current.name,
          description: currentMailingRef.current.description,
          date: moment().toISOString(),
          abfrageId: currentAbfrageRef.current._id,
          mgvIds: currentAbfrageRef.current.result.map(member => { return member.mgvId })
        }
      }
    });

    if (res.data.updateMailing) {
      setShowModalMailingState(false);
      currentMailingRef.current.name = "";
      currentMailingRef.current.description = "";
    }
    if (res.error) {
      alert("Fehler beim speichern des Mailings");
    }
  }

  const handleRunQuery = async () => {


    const vars = {};
    vars["query"] = JSON.stringify(mongodbFormat(currentAbfrageRef.current.definition, configRef.current), "abgfrageQuery", 2);;
    console.log(vars);
    var res = await sendQuery({ variables: vars });


    if (res.data.memberQuery) {
      currentAbfrageRef.current = { ...currentAbfrageRef.current, result: res.data.memberQuery };
      setRenderState(!renderState);
      setShowCsvDownloadButton(false);
    }
    else if (res.error) {
      alert("Fehler beim Ausführen der Abfrage");
    }
  };

  const handleDeleteAbfrage = async () => {
    var res = await deleteAbfrage({
      variables: {
        abfrageInput: {
          _id: currentAbfrageRef.current._id
        }
      }
    });
    currentAbfrageRef.current = undefined;
    console.log(res);
  }
  const abfrageColumns = [
    {
      title: 'Name',
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: 'Beschreibung',
      dataIndex: ['description'],
      key: 'description',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },

    {
      title: 'Aktion',
      key: 'action',
      render: (record) => (
        <div>
          <Button onClick={deleteAbfrageHaendler.bind(null, record)}>
            <DeleteOutlined></DeleteOutlined>
          </Button>
        </div>
      ),
    },
  ];

  const handleSaveCsvLog = async () => {

    var res = await updateCsvLog({
      variables: {
        csvLogInput: {
          memberIds: currentAbfrageRef.current.result.map(member => { return member._id })
        }
      }
    })
  }

  const buildConfig = () => {
    if (dataLandesVerbaende && dataLandesVerband) {
      config.fields.landesVerband.fieldSettings.listValues = dataLandesVerbaende.landesVerbaende.map(verband => { return verband.name })
      let landKreise = [];

      config.fields.landKreis.fieldSettings.listValues = [
        { value: "1", title: "header1" },
        { value: "11", title: "sub11", parent: "1" },
        { value: "12", title: "sub12", parent: "1" },
        { value: "2", title: "header2" },
        { value: "21", title: "sub21", parent: "2" },
      ];

      console.log(config);
    }
    return config;
  };

  const renderBuilder = (props) => {
    console.log("render");
    return (
      <div className="query-builder-container" style={{ padding: "10px", zIndex: "1" }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    );
  }

  // const resultColumns =[
  //   {
  //     title:"Vorname",
  //     dataIndex:'firstName',
  //     key:"firstName",
  //     render:()=><p style={{marginTop:"auto", marginBottom:"auto"}}>Mustervorname</p>
  //   },
  //   {
  //     title:"Nachname",
  //     dataIndex:'name',
  //     key:"name",
  //     render:()=><p style={{marginTop:"auto", marginBottom:"auto"}}>Mustername</p>
  //   },
  //   {
  //     title:"Email",
  //     dataIndex:'email',
  //     key:"email",
  //     render:()=><p style={{marginTop:"auto", marginBottom:"auto"}}>Muster@email.de</p>
  //   },
  //   {
  //     title:"Adresse",
  //     dataIndex:"_address",
  //     key:"_address",
  //     render:(address)=><p style={{marginTop:"auto", marginBottom:"auto"}}>Musterstraße<br></br>{address.postal_code} {address.locality}</p>
  //   },
  //   {
  //     title:"Arbeitgeber",
  //     dataIndex:"nameAg",
  //     key:"nameAg"
  //   },
  //   {
  //     title:"Landesverband",
  //     dataIndex:['_landesVerband','name'],
  //     key:"_landesVerband"
  //   },    
  //   {
  //     title:"Landkreis",
  //     dataIndex:["_landKreis","name"],
  //     key:"_landKreis",
  //   },
  //   {
  //     title:"Anrede",
  //     dataIndex:"briefAnrede",
  //     key:"briefAnrede",
  //     render:()=><p style={{marginTop:"auto", marginBottom:"auto"}}>Sehr geehrte Damen und Herren</p>
  //   },
  // ]

  const resultColumns = [
    {
      title: "Vorname",
      dataIndex: 'firstName',
      key: "firstName"
    },
    {
      title: "Nachname",
      dataIndex: 'name',
      key: "name"
    },
    {
      title: "Geburtsdatum",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (record) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{moment(record).format("DD.MM.YYYY")}</p>
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: "email"
    },
    {
      title: "Mitgliedsnummer",
      dataIndex: ['mgvId'],
      key: "mgvId"
    },
    {
      title: "Adresse",
      dataIndex: "_address",
      key: "_address",
      render: (address) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{address.postal_code} {address.locality}</p>
    },
    {
      title: "Adr. Arbeitgeber",
      dataIndex: "_addressAg",
      key: "_addressAg",
      render: (address) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{address.throughfare}<br></br>{address.postal_code} {address.locality}</p>
    },
    {
      title: "Arbeitgeber",
      dataIndex: "nameAg",
      key: "nameAg",
    },
    {
      title: "Tarifbindung",
      dataIndex: "tarifBindung",
      key: "tarifBindung",
    },
    {
      title: "Landesverband",
      dataIndex: ['_landesVerband', 'name'],
      key: "_landesVerband"
    },
    {
      title: "Landkreis",
      dataIndex: ["_landKreis", "name"],
      key: "_landKreis",
    },
    {
      title: "zul. Aktualisiert",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      render: (record) => <p style={{ marginTop: "auto", marginBottom: "auto" }}>{moment(record).format("DD.MM.YYYY")}</p>
    },

  ]

  const exportHeaders = [
    { label: "Vorname", key: "firstName" },
    { label: "Nachname", key: "name" },
    { label: "Geburtsdatum", key: "birthDate" },
    { label: "Email", key: "email" },
    { label: "Plz", key: "_address.postal_code" },
    { label: "Ort", key: "_address.locality" },
    { label: "Arbeitgeber", key: "nameAg" },
    { label: "Arbeitgeber-Straße", key: "_addressAg.throughfare" },
    { label: "Arbeitgeber-Plz", key: "_addressAg.postal_code" },
    { label: "Arbeitgeber-Ort", key: "_addressAg.locality" },
    { label: "Tarifbindung", key: "tarifBindung" },
    { label: "Landesverband", key: "_landesVerband.name" },
    { label: "Landkreis", key: "_landKreis.name" },
    { label: "MGV Id", key: "mgvId" },
  ]

  const ComposedList = ScrollingLock()(() => {

    return (
      <div style={{ height: "100%", overflow: "auto", width: "394px" }}>
        <Row justify="end" style={{ margin: "16px 32px 0 0" }}>
          <Button onClick={handleNewAbfrage}><PlusOutlined></PlusOutlined></Button>
          {/* <Button onClick={handleSaveAll}><SaveOutlined></SaveOutlined></Button> */}
        </Row>
        <Row style={{ width: "100%", height: "calc(100vh - 179px)" }}>


          <div style={{ marginLeft: "auto", marginRight: "auto", width: "90%" }}>
            {abfragenRef.current.map(abfrage => {
              return (
                <div onWheel={e => e.stopPropagation()} className='listItem' style={{ paddingLeft: "8px", paddingRight: "8px" }} onClick={handleAbfrageSelect.bind(this, { abfrage })}>
                  <h2 className='titletext-h2'>{abfrage.name}</h2>
                  <p>{abfrage.description}</p>
                  <Divider></Divider>
                </div>
              )
            })
            }
          </div>

        </Row>
      </div>
    )
  }
  )

  return (
    <React.Fragment>
      <div className="pageContainer">
        <div style={{ marginBottom: "16px", width: "400px", position: "fixed", backgroundColor: "white", height: "100%", borderStyle: "none solid none none", borderWidth: "1px", borderColor: "gray", zIndex: "10" }}>
          {(loadingAbfragen || loadingLandesVerbaende || loadingLandesVerband) && <Spin size="large" style={{ margin: "auto", width: "100%", marginTop: "32px" }}></Spin>}
          {abfragenRef.current &&
            <ComposedList style={{ width: "100%" }}>

            </ComposedList>
          }

        </div>
        <Row style={{ height: "100%" }}>
          <div style={{ marginLeft: "416px", display: "flex", flexDirection: "column", width: "100%" }}>
            {configRef.current && currentAbfrageRef.current &&
              <div >
                <Row style={{ paddingLeft: "32px", paddingRight: "32px" }}>
                  <h1 className="titletext-h1" style={{ minWidth: "250px", borderStyle: "none none solid none", borderWidth: "0.5px", borderColor: "gray", marginRight: "auto" }}>{currentAbfrageRef.current.name}</h1>
                  <Button style={{ margin: "auto 0px" }} onClick={handleAbfrageEdit}><EditOutlined></EditOutlined></Button>
                  <Button style={{ margin: "auto 0px" }} onClick={() => handleQuerySave(currentAbfrageRef.current)}><SaveOutlined></SaveOutlined></Button>
                  <Button style={{ margin: "auto 0px" }} onClick={handleRunQuery}> Ausführen</Button>
                  <Button style={{ margin: "auto 0px" }} onClick={handleDeleteAbfrage}> <DeleteOutlined></DeleteOutlined></Button>
                </Row>
                <Row>
                  <p style={{ paddingLeft: "32px", paddingRight: "32px" }}>{currentAbfrageRef.current.description}</p>
                </Row>
                <Row>
                  <Query
                    {...configRef.current}
                    onChange={handleQueryChanged.bind(this)}
                    renderBuilder={renderBuilder}
                    value={currentAbfrageRef.current.definition}
                  />
                </Row>
                <Row style={{ paddingLeft: "32px", paddingRight: "32px" }}>
                  {loadingSendQuery ?
                    <div>
                      <Divider orientation='left'><h1 className="titletext-h1">Abfrage Ergebnis</h1></Divider>
                      <Spin style={{ margin: "auto" }} size="large"></Spin>
                    </div> :
                    <div>
                      {currentAbfrageRef.current.result &&
                        <div>
                          <Divider orientation='left'><h1 className="titletext-h1">Abfrage Ergebnis</h1></Divider>
                          <Row>
                            <p style={{ marginRight: "auto" }}>{currentAbfrageRef.current.result.length} Mitglieder gefunden</p>
                            {!showCsvDownloadButton &&
                            <Button type="primary" onClick={()=>{setShowTwoFactorModal(true)}}>Exportieren</Button>}
                            {showCsvDownloadButton && 
                            <CSVLink className="ant-btn"
                              data={currentAbfrageRef.current.result}
                              target="_blank" filename={"Mitgliederabfrage-" + currentAbfrageRef.current.name + ".csv"}
                              headers={exportHeaders}
                              separator={";"}
                              onClick={handleSaveCsvLog}
                            >
                              Exportieren
                            </CSVLink>
                            }
                            <Button onClick={() => setShowModalMailingState(true)}>Mailing erstellen</Button>
                          </Row>

                          <Table columns={resultColumns} dataSource={currentAbfrageRef.current.result}></Table>
                        </div>
                      }

                    </div>
                  }
                </Row>



              </div>
            }
          </div>
        </Row>


      </div>
      {currentAbfrageRef.current && showModalState &&
        <Modal onOk={handleModalOk} onCancel={() => setShowModalState(false)} visible={showModalState} >
          <Divider orientation='left'>Name</Divider>
          <Input placeholder='Name' defaultValue={currentAbfrageRef.current.name} onChange={handleCurrentNameChange}></Input>
          <Divider orientation='left'>Beschreibung</Divider>
          <TextArea placeholder='Beschreibung' defaultValue={currentAbfrageRef.current.description} onChange={handleCurrentDescriptionChange}></TextArea>
        </Modal>
      }
      {showModalMailingState &&
        <Modal onOk={() => handleModalMailingOk()} onCancel={() => setShowModalMailingState(false)} visible={showModalMailingState}>
          <Divider orientation='left'>Name</Divider>
          <Input placeholder='Name' onChange={(event) => currentMailingChangeHandler("name", event.target.value)}></Input>
          <Divider orientation='left'>Beschreibung</Divider>
          <TextArea placeholder='Beschreibung' onChange={(event) => currentMailingChangeHandler("description", event.target.value)}></TextArea>
        </Modal>
      }
      {showTwoFactorModal &&
        <TwoFactorModal visible={showTwoFactorModal} onCancel={()=>{setShowTwoFactorModal(false)}} callback={()=>{setShowCsvDownloadButton(true); setShowTwoFactorModal(false)} }  ></TwoFactorModal>
      }

    </React.Fragment>);

}

export default StartPage;
