import {gql } from '@apollo/client';


const requestBodyRoles = gql `
query Roles {
    roles {
        _id
        name
        entityPermissions{
            entity{s
                _id
                name
            }
            privileges{
                read
                write
                delete
            }
        }
    }
},`;


const updateBodyRole = gql `
mutation updateRole($roleInput:RoleInput!){
    updateRole(roleInput:$roleInput) {
        _id
        name
        entityPermissions{
            entity{
                _id
                name
            }
            privileges{
                read
                write
                delete
            }
        }
    }
},`;

const deleteBodyRole = gql `
mutation DeleteRole($roleId:ID!){
    deleteRole(roleId:$roleId) 
},`;


export const getRolesQuery = requestBodyRoles;
export const updateRoleMutation = updateBodyRole;
export const deleteRoleMutation = deleteBodyRole;